<template>
   <ul class="pagination justify-content-center">
    <li class="page-item" @click="goToPrev">
      <a class="page-link" href="#" tabindex="-1">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li
      v-for="page in pagination"
      :class="{ 'active': page === modelValue }"
      :key="`app-pagination-page-${page}`"
      @click.prevent="updatePageHandler(page)"
      class="page-item"
    >
      <a v-if="page" class="page-link" href="#">{{ page }}</a>
      <a v-else class="page-link" href="#"> ... </a>
    </li>
    <li class="page-item" @click="goToNext">
      <a class="page-link" href="#">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
</template>

<script>
import { computed } from 'vue';

export default {
  emits: ['update:modelValue'],
  props: {
    pages: {
      type: Number,
      default: 0,
    },
    rangeSize: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    activeColor: {
      type: String,
      default: '#DCEDFF',
    },
    hideFirstButton: {
      type: Boolean,
      default: false,
    },
    hideLastButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const pagination = computed(() => {
      const res = [];
      const minPaginationElems = 5 + props.rangeSize * 2;

      let rangeStart = props.pages <= minPaginationElems ? 1 : props.modelValue - props.rangeSize;
      let rangeEnd =
        props.pages <= minPaginationElems ? props.pages : props.modelValue + props.rangeSize;

      rangeEnd = rangeEnd > props.pages ? props.pages : rangeEnd;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;

      if (props.pages > minPaginationElems) {
        const isStartBoundaryReached = rangeStart - 1 < 3;
        const isEndBoundaryReached = props.pages - rangeEnd < 3;

        if (isStartBoundaryReached) {
          rangeEnd = minPaginationElems - 2;
          for (let i = 1; i < rangeStart; i++) {
            res.push(i);
          }
        } else {
          res.push(1);
          res.push(null);
        }

        if (isEndBoundaryReached) {
          rangeStart = props.pages - (minPaginationElems - 3);
          for (let i = rangeStart; i <= props.pages; i++) {
            res.push(i);
          }
        } else {
          for (let i = rangeStart; i <= rangeEnd; i++) {
            res.push(i);
          }
          res.push(null);
          res.push(props.pages);
        }
      } else {
        for (let i = rangeStart; i <= rangeEnd; i++) {
          res.push(i);
        }
      }

      return res;
    });

    function updatePageHandler(params) {
      emit('update:modelValue', params);
    }

    // controls
    const isPrevControlsActive = computed(() => {
      return props.modelValue > 1;
    });
    const isNextControlsActive = computed(() => {
      return props.modelValue < props.pages;
    });

    function goToFirst() {
      if (isPrevControlsActive.value) {
        emit('update:modelValue', 1);
      }
    }
    function goToPrev() {
      if (isPrevControlsActive.value) {
        emit('update:modelValue', props.modelValue - 1);
      }
    }

    function goToLast() {
      if (isNextControlsActive.value) {
        emit('update:modelValue', props.pages);
      }
    }

    function goToNext() {
      if (isNextControlsActive.value) {
        emit('update:modelValue', props.modelValue + 1);
      }
    }

    return {
      pagination,
      updatePageHandler,
      isPrevControlsActive,
      isNextControlsActive,
      goToFirst,
      goToLast,
      goToPrev,
      goToNext,
    };
  },
}
</script>
